<template>
  <div style="text-align:center;padding-left: 10px;">
    <b-navbar>
      <template #brand>
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <img
              src="../assets/logo.png">
        </b-navbar-item>
      </template>
      <template #start>
        <b-navbar-item tag="router-link" to="/">
          首页
        </b-navbar-item>
        <b-navbar-dropdown label="分类" :hoverable="true">
          <b-navbar-item v-for="(item,index) in cats"
                         :key="index"
                         tag="router-link"
                         :to="{name:'home',query:{cat:item.name}}"
                         style="width: 120px; padding-left: 10px; padding-right: 5px;">
            <span style="float: left; width: 60px;">{{ item.name }}</span>
            <span style="float:right;width: 45px;">({{ item.num }})</span>
          </b-navbar-item>
        </b-navbar-dropdown>
        <b-navbar-item tag="router-link" to="tags">
          标签
        </b-navbar-item>
        <b-navbar-item tag="router-link" to="about">
          关于
        </b-navbar-item>
        <b-navbar-item tag="div">
          <b-field>
            <b-input
                v-model="searchText"
                placeholder="搜索"
                icon="magnify"
                rounded
                @keyup.enter.native="search"></b-input>
          </b-field>
        </b-navbar-item>
      </template>
    </b-navbar>
  </div>
</template>
<script>

export default {
  name: "Navbar",
  data() {
    return {
      current: 'home',
      searchText: "",
      cats: [],
    };
  },
  methods: {
    search() {
      if (this.searchText !== "") {
        this.$router.push({
          "name": "home",
          "query": {
            "key": this.searchText,
          }
        })
      }
    }
  },
  created() {
    this.$axios.get("/cats.json").then((resp) => {
      this.cats = resp.data;
    })
  },
  watch: {
    searchText(nVal) {
      // TODO
      console.log(nVal);
    },
    $route() {
      if (this.$route.query.key) {
        this.searchText = this.$route.query.key;
      } else {
        this.searchText = "";
      }
    }
  }
};
</script>
<style scoped>
</style>
