import Vue from "vue"
import VueRouter from "vue-router";

Vue.use(VueRouter)
const routes = [
    {
        path: "/about",
        name: "about",
        component: ()=>import('./pages/AboutPage'),
    },
    {
        path:"/",
        name:"home",
        component:()=>import('./pages/PostListPage'),
    },
    {
        path:"/tags",
        name:"tags",
        component:()=>import('./pages/TagListPage'),
    },
    {
        path:"/detail/:permalink",
        name:"detail",
        component: ()=>import('./pages/DetailPage'),
    },
    // {
    //     path:"/message",
    //     name:"message",
    //     component: ()=>import('./pages/MessageBoardPage'),
    // },
    {
        path:"/books",
        name:"books",
        component: ()=>import('./pages/BookshelfPage'),
    }
]

const router = new VueRouter({
    routes
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

export default router
