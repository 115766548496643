import Vue from 'vue'
import App from './App.vue'
import router from "./routes";

Vue.config.productionTip = false
import {library} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {
    faFolderOpen,
    faTags,
    faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";

library.add(
    faFolderOpen,
    faTags,
    faCalendarAlt
);
Vue.component('font-awesome-icon', FontAwesomeIcon);
import Navbar from "buefy";
// import 'buefy/dist/buefy.css';
Vue.use(Navbar);

import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
// use
Vue.use(mavonEditor)

import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';

VMdPreview.use(githubTheme);

Vue.use(VMdPreview);


import axios from 'axios'
// axios不需要Vue.use()
Vue.prototype.$axios = axios    //全局注册，使用方法为:this.$axios
new Vue({
    render: h => h(App),
    router, // 必须是router或者指定router:xxx
}).$mount('#app')
